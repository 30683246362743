<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <div class="search_center">
              <span>填报人：</span>
              <el-input
                v-model="parameter.userName"
                @change="dataUpdate"
                class="ipt_width"
                placeholder="请输入填报人"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center">
              <span>申请人：</span>
              <el-input
                v-model="parameter.applyByName"
                @change="dataUpdate"
                class="ipt_width"
                placeholder="请输入申请人"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center">
              <span> 单据编号：</span>
              <el-input
                v-model="parameter.expenseNumber"
                @change="dataUpdate"
                class="ipt_width expense__width"
                placeholder="请输入单据编号"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center">
              <span>流程进度：</span>
              <Dictionary
                v-model="parameter.actStatus"
                class="ipt_width"
                code="REGISTRATION_EXPENDITURE_ACT_STATUS"
                placeholder="请选择流程进度"
              />
            </div>

            <div class="search_center">
              <span>申请时间：</span>
              <el-date-picker
                class="date_width"
                v-model="createdDate"
                :pickerOptions="pickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="申请日期（始）"
                end-placeholder="申请日期（末）"
              >
              </el-date-picker>
            </div>
            <div class="search_center">
              <span>发生日期：</span>
              <el-date-picker
                class="date_width"
                v-model="occurCreatedDate"
                :pickerOptions="occurPickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="发生日期（始）"
                end-placeholder="发生日期（末）"
              >
              </el-date-picker>
            </div>
            <div class="search_center">
              <span>单据金额：</span>
              <NumberRangeInput v-model="numberRange" />
            </div>

            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" @click="drawer = true">登记流程</el-button>
          <el-button type="success" style="padding: 0"
            ><a
              style="display: block; height: 40px; width: 100px; line-height: 40px"
              target="_blank"
              href="https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/支出登记管理操作手册.pdf"
              >操作文档</a
            ></el-button
          >
          <el-button
            type="primary"
            icon="el-icon-printer"
            :loading="exportingLoading"
            v-if="permission(['ALL_DOCUMENT_EXPORT'])"
            @click="exportingFn"
            >导出</el-button
          >
        </div>
      </div>

      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column align="center" width="50" type="index" label="序号"></el-table-column>
            <el-table-column
              align="center"
              prop="expenseNumber"
              width="160"
              label="单据编号"
            ></el-table-column>
            <el-table-column
              align="center"
              width="100"
              prop="createName"
              sortable
              label="填报人"
            ></el-table-column>
            <el-table-column
              align="center"
              width="100"
              prop="applyName"
              sortable
              label="申请人"
            ></el-table-column>
            <el-table-column
              align="center"
              sortable
              width="110"
              prop="expenseType"
              label="费用类型"
            >
              <template slot-scope="scope">
                {{ scope.row.expenseType | dict(expenseTypeList) }}
              </template></el-table-column
            >
            <el-table-column
              align="center"
              sortable
              min-width="320"
              :show-overflow-tooltip="false"
              prop="expenseBelongName"
              label="费用所属"
            >
            </el-table-column>
            <el-table-column
              align="center"
              :show-overflow-tooltip="false"
              sortable
              width="110"
              prop="companyType"
              label="申请单位"
            >
              <template slot-scope="scope">
                {{ scope.row.companyType | dict(companyTypeList) }}
              </template></el-table-column
            >
            <el-table-column align="center" sortable width="110" prop="sumCost" label="申请金额">
              <template slot-scope="scope">
                {{ scope.row.sumCost | applyAmount }}
              </template></el-table-column
            >

            <el-table-column
              align="center"
              min-width="110"
              sortable
              prop="taskDate"
              label="申请时间"
            >
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              min-width="110"
              sortable
              prop="occurDate"
              label="发生日期"
            >
              <template slot-scope="scope">
                {{ scope.row.occurDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" sortable width="150" prop="statusName" label="流程进度">
            </el-table-column>

            <el-table-column prop="title" align="center" fixed="right" width="70" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  class="text_Details_Bgc"
                  @click="detail(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog width="75%" title="库管登记流程图" :visible.sync="drawer">
      <div style="padding: 5px">
        <img src="@/assets/库管支出登记流程图.png" alt="库管登记流程图" width="100%" />
      </div>
    </el-dialog>
    <Edit :isShow.sync="showEdit" :getTitle="getTitle" :options="form"></Edit>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    Edit: () => import('../Edit.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    NumberRangeInput: () => import('@/components/number-range-input.vue'),
  },
  data() {
    return {
      parameter: {
        userName: null,
        applyByName: null,
        expenseNumber: null,
        actStatus: null,
        startCost: null,
        endCost: null,
        registrationType: 'INVENTORY_KEEPER', //库管
        pageNow: 1,
        pageSize: 50,
        total: 0,
      },
      numberRange: [],
      pageSize: 0,
      tableData: [],
      loading: false,
      showEdit: false,
      form: {},
      createdDate: [],
      occurCreatedDate: [],
      drawer: false,
      getTitle: '',
      companyTypeList: [],
      expenseTypeList: [],
      // registrationTypeList: [],
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
      occurPickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.occurCreatedDate, 0, v.minDate.getTime())
            this.$set(this.occurCreatedDate, 1, '')
          }
        },
      },
      exportingLoading: false,
    }
  },
  provide() {
    return {
      re: this.re,
    }
  },
  watch: {
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
    occurCreatedDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.occurCreatedDate = []
        }
      },
    },
  },

  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getType()
    this.getData()
  },
  filters: {
    applyAmount(n) {
      if (!n) return
      return Number(n).toLocaleString()
    },
  },
  methods: {
    exportingFn() {
      this.exportingLoading = true
      this.$api.register
        .listExport(this.parameter)
        .then(res => {
          const fileName = '库管支出登记明细.xlsx'
          const blob = new Blob([res], {
            type: 'application/vnd.ms-excel',
          })
          if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName)
          } else {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = fileName
            link.click()
            URL.revokeObjectURL(link.href)
          }
          this.exportingLoading = false
          this.$message({
            message: '导出成功!',
            type: 'success',
          })
        })
        .catch(e => {
          this.exportingLoading = false
          console.log(e)
        })
    },
    getType() {
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.companyTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('EXPENSE_TYPE', true)
        .then(res => {
          this.expenseTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
      // this.$api.dict
      //   .listSysDictData('REGISTRATION_TYPE', true)
      //   .then(res => {
      //     this.registrationTypeList = res.data
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
    },
    getData() {
      this.loading = true

      if (this.createdDate && this.createdDate != []) {
        this.parameter.startDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      } else if (this.createdDate == null) {
        this.parameter.startDate = null
        this.parameter.endDate = null
      }
      if (this.occurCreatedDate && this.occurCreatedDate != []) {
        this.parameter.occurStartDate = new Date(this.occurCreatedDate[0]).getTime()
        this.parameter.occurEndDate = new Date(this.occurCreatedDate[1]).getTime()
      } else if (this.occurCreatedDate == null) {
        this.parameter.occurStartDate = null
        this.parameter.occurEndDate = null
      }
      if (this.numberRange && this.numberRange.length > 0) {
        this.parameter.startCost = this.numberRange[0]
        this.parameter.endCost = this.numberRange[1]
      } else {
        this.parameter.startCost = null
        this.parameter.endCost = null
      }
      this.$api.register
        .registrationExpenditureAllList(this.parameter)
        .then(res => {
          this.loading = false
          if (res.data == []) {
            this.tableData = res.data
          } else {
            this.tableData = res.data ? (res.data.records ? res.data.records : []) : []
            this.parameter.total = res.data ? (res.data.total ? res.data.total : 0) : 0
            this.tableData.forEach(item => {
              if (item.disbursementType == 'UNDEFINED_RATIFICATION_IMPLEMENT') {
                item.expenseBelongName = item.businessContent
              }
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    detail(row) {
      this.getTitle = '查看登记单详情'
      row.list_s = 4
      this.form = {
        ...row,
      }
      this.showEdit = true
    },
    re() {
      this.form = {}
      this.getData()
      this.showEdit = false
    },
    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.userName = null
      this.parameter.applyByName = null
      this.parameter.expenseNumber = null
      this.parameter.actStatus = null
      this.parameter.startDate = null
      this.parameter.endDate = null
      this.parameter.occurStartDate = null
      this.parameter.occurEndDate = null
      this.parameter.startCost = null
      this.parameter.endCost = null
      this.numberRange = []
      this.createdDate = []
      this.occurCreatedDate = []
      this.parameter.pageNow = 1
      this.getData() /** 加载 数据 **/
    },
    dataUpdate() {
      this.$forceUpdate()
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.iconCost {
  padding-left: 10px;
  font-size: 28px;
  vertical-align: middle;
  cursor: pointer;
}
</style>
